import { createRouter, createWebHistory } from 'vue-router'

export const constantRoutes = [
  {
    path: '/login',
    name: 'login',
    meta: {
      hideTabBar: true,//在路由meta对象设置一个布尔值showTabBar
      'title': '登录'
    },
    component: () => import('@/views/Login/index.vue')
  }
]

export const asyncRoutes = [
  {
    path: '/index',
    name: 'home',
    meta: {
      title: '首页'
    },
    component: () => import('@/views/Index/'),
    children: [
      {
        path: '/',
        redirect: '/index'
      },
      {
        path: '/index',
        name: 'index',
        meta: {
          title: '首页',
        },
        component: () => import('@/views/Index/index.vue'),
      },
    ]
  },
  {
    path: '/mine',
    name: 'mine',
    meta: {
      title: '个人中心',
    },
    component: () => import('@/views/Mine/index'),
  },
  {
    path: '/oneKey',
    name: 'oneKey',
    component: () => import('@/views/One/index'),
    meta: {
      title: '扫码页面'
    }
  },
  {
    path: '/location',
    name: 'location',
    component: () => import('@/views/location/pull'),
    meta: {
      title: '库位状态拉'
    }
  },
  {
    path: '/a',
    name: 'a',
    component: () => import('@/views/location/pull'),
    meta: {
      title: '库位状态拉'
    }
  },
  {
    path: '/locationB',
    name: 'locationB',
    component: () => import('@/views/location/push'),
    meta: {
      title: '库位状态推'
    }
  },
  {
    path: '/orderA',
    name: 'orderA',
    component: () => import('@/views/Order/pull'),
    meta: {
      title: '订单拉'
    }
  },
  {
    path: '/orderB',
    name: 'orderB',
    component: () => import('@/views/Order/push'),
    meta: {
      title: '订单推动'
    }
  },
  {
    path: '/scanA',
    name: 'scanA',
    component: () => import('@/views/Scan/pull'),
    meta: {
      title: '扫描拉动'
    }
  },
  {
    path: '/scanB',
    name: 'scanB',
    component: () => import('@/views/Scan/push'),
    meta: {
      title: '扫描推'
    }
  },
  {
    path: '/steelA',
    name: 'steelA',
    component: () => import('@/views/Steel/pull'),
    meta: {
      title: '钢托拉动'
    }
  },
  {
    path: '/steelB',
    name: 'steelB',
    component: () => import('@/views/Steel/push'),
    meta: {
      title: '钢托拉动推'
    }
  },
  {
    path: '/other',
    name: 'other',
    component: () => import('@/views/Other/index'),
    meta: {
      title: '其他作业'
    }
  },
  {
    path: '/task',
    name: 'task',
    component: () => import('@/views/Task/index'),
    meta: {
      title: '任务查询'
    }
  },
  {
    path: '/inventory',
    name: 'inventory',
    component: () => import('@/views/Inventory/index'),
    meta: {
      title: '库存查询'
    }
  },
  {
    path: '/business',
    name: 'business',
    component: () => import('@/views/Business/index'),
    meta: {
      title: '业务入口'
    }
  },
  {
    path: '/recent',
    name: 'recent',
    component: () => import('@/views/Recent/index'),
    meta: {
      title: '最近任务入口'
    }
  },
  {
    path: '/take',
    name: 'take',
    component: () => import('@/views/Take/index'),
    meta: {
      title: '盘点'
    }
  },
  {
    path: '/take/add',
    name: 'takeAdd',
    component: () => import('@/views/Take/detail'),
    meta: {
      title: '盘点新增'
    }
  },
  {
    path: '/take/edit/:id',
    name: 'takeEdit',
    component: () => import('@/views/Take/detail'),
    meta: {
      title: '盘点新增'
    }
  }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: constantRoutes,
})

export function resetRouter() {
  const newRouter = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: constantRoutes
  })
  router.matcher = newRouter.matcher // reset router
}

export default router
